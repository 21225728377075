import moment from "moment";
import { Link } from "react-router-dom";
import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import WarningModal from "../Common/WarningModal";
import DeleteWarningModal from "../Common/DeleteWarningModal";
import dImage from "../../assets/images/default-image.jpg";
import { AgGridReact } from "ag-grid-react";
import { UnPublishEvent } from "../../utils/myevent/MyEvent";
import { ToastContainer, toast } from "react-toastify";

const MyEventsListCard = ({ rowData, baseUrls, someFilterHide, getMyEvent, handleDeleteEvent, event_status,profileData}) => {
  const [currencyIcn, setCurrencyIcn] = useState('');
  const [eventIdToDelete, setEventIdToDelete] = useState('');
  const [columnDefs, setColumnDefs] = useState([]);
  const [unPublishBtnLoading, setUnPublishBtnLoading] = useState(false);
  const gridRef = useRef();


  const cardImageError = (event) => {
    event.target.src = dImage;
    event.target.onError = null;
  };


  const onGridReady = (params) => {
    gridRef.current = params.api;
  };

  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel();
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      filter: "agTextColumnFilter",
      floatingFilter: true,
    };
  }, [])

  const AvatarRenderer = ({ value, data }) => {
    return (
      <div className="">
        {/* <Link
          to={`/eventdetail/${data?.id}`} state={{ type: 'Events' }}
        >
          <img
            className="listcard-img object-fit-cover"
            src={`${baseUrls?.logo_image_url}/${data?.logo}`}
            alt="img"
          />
        </Link> */}
        <Link className="navigate-detail-btn"
          to={someFilterHide ? `/pasteventdetail/${data?.id}` : `/eventdetail/${data?.id}`}
          state={{
            type: someFilterHide
              ? "Past Events"
              : "My Events"
          }}>
          <img className="listcard-img" src={someFilterHide ? `${baseUrls?.past_event_logo_image_url}/${data?.logo}` : `${baseUrls?.logo_image_url}/${data?.logo}`} onError={cardImageError} alt="img" />
        </Link>
      </div>
    );
  };

  const handleUnPublish = async (EId) => {
    const fData = {
      id: EId
    }
    setUnPublishBtnLoading(true)
    try {
      const response = await UnPublishEvent(fData);
      //  if(response?.data?.success){
      toast.success(response?.data?.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        theme: "light",
      });
      getMyEvent()
      // }
    } catch (err) {
      console.log(err);
    } finally {
      setUnPublishBtnLoading(false);
    }
  }

  useEffect(() => {
    rowData?.map((val) => {
      if (val?.currency_code == "INR") {
        setCurrencyIcn("₹");
      } else if (val?.currency_code == "USD") {
        setCurrencyIcn("$");
      } else if (val?.currency_code == "EUR") {
        setCurrencyIcn("€");
      } else if (val?.currency_code == "GBP") {
        setCurrencyIcn("€");
      }
    })
  }, [rowData]);

  useEffect(() => {
    setColumnDefs([
      {
        headerName: "Image",
        field: "logo",
        width: 100,
        cellRenderer: AvatarRenderer,
        filter: false,
      },
      {
        headerName: "Title",
        field: "event_title",
        cellRenderer: ({ value, data }) => {
          return (
            <Link className="navigate-detail-btn"
              to={someFilterHide ? `/pasteventdetail/${data?.id}` : `/eventdetail/${data?.id}`}
              state={{
                type: someFilterHide
                  ? "Past Events"
                  : "My Events"
              }}>
              <p className="listcard-title">{data?.event_title}</p>
            </Link>
          );
        },
        // filter: params => params.data.editable,
      },
      {
        headerName: "Start Date",
        field: "start_date",
        cellRenderer: ({ value, data }) => {
          return (<div className="d-flex gap-1 align-items-center">
            <p className="text-dark">{moment(value).format("ddd, DD MMM YYYY")} </p>
          </div>
          );
        },
        // cellRenderer: AvatarRenderer,
        // filter: params => params.data.editable,
      },
      {
        headerName: "End Date",
        field: "end_date",
        cellRenderer: ({ value, data }) => {
          return (<div className="d-flex gap-1 align-items-center">
            <p className="text-dark">{moment(value).format("ddd, DD MMM YYYY")} </p>
          </div>
          );
        },
        // cellRenderer: AvatarRenderer,
        // filter: params => params.data.editable,
      },
      {
        headerName: "Publish Date",
        field: "published_date",
        cellRenderer: ({ value, data }) => {
          return (
            <>
              {value == null ||
                value == "0000-00-00" ? "" :
                <div className="d-flex align-items-center gap-1 ">
                  <p className=" text-dark" title={moment(value).format("ddd, DD MMM YYYY")}>
                    {moment(value).format("ddd, DD MMM YYYY")}
                  </p>
                </div>
              }
            </>
          );
        },
      },
      {
        headerName: "CutOff Date",
        field: "published_end_date",
        cellRenderer: ({ value, data }) => {
          return (
            <>
              {value == null ||
                value == "0000-00-00" ? "" :
                <div className="d-flex align-items-center gap-1 ">
                  <p className=" text-dark" title={moment(value).format("ddd, DD MMM YYYY")}>
                    {moment(value).format("ddd, DD MMM YYYY")}
                  </p>
                </div>
              }
            </>
          );
        },
      },
      {
        headerName: "Event Create With",
        field: "is_express_event",
        cellRenderer: ({ value, data }) => {
          return (
            <>
              {
                <div className="d-flex align-items-center gap-1 ">
                  <p className=" text-dark">
                    {value == 0 ? "Advance" : "Express"}
                  </p>
                </div>
              }
            </>
          );
        },
      },
      {
        headerName: "Event Status",
        field: "event_status",
        width: 120
        // cellRenderer: AvatarRenderer,
        // filter: params => params.data.editable,
      },
      {
        headerName: "Location",
        field: "address",
        width: 350,
        cellRenderer: ({ value, data }) => {
          return (
            <>
              {value ?
                <p className="text-dark" title={`${data?.city?.city_name}, ${data?.state?.short_name}, ${data?.country?.short_name}`}>
                  {/* {value}, {data?.city?.city_name}, {data?.state?.short_name}, {data?.country?.short_name} */}
                  {data?.city?.city_name ? `${data?.city?.city_name},` : "" } {data?.state?.short_name ? `${data?.state?.short_name},` : ""} {data?.country?.short_name}
                </p>
                : <p className="text-dark" title={`${data?.city?.city_name}, ${data?.state?.short_name}, ${data?.country?.short_name}`}>
                  {data?.city?.city_name ? `${data?.city?.city_name},` : "" } {data?.state?.short_name ? `${data?.state?.short_name},` : ""} {data?.country?.short_name}
                </p>
              }
            </>
          );
        },
        // cellRenderer: AvatarRenderer,
        // filter: params => params.data.editable,
      },
      {
        headerName: "From",
        field: "min_amount",
        cellRenderer: ({ value, data }) => {
          return (
            <>
              <div className="d-flex align-items-center gap-1">
                <p className="feature-card-cutoff-p">
                 {value ? `${currencyIcn}${value}` :'-'}  
                </p>
              </div>
            </>
          );
        },
      },
      // {
      //   headerName: "Max",
      //   field: "max_amount",
      //   cellRenderer: ({ value, data }) => {
      //     return (
      //       <>
      //        <div className="d-flex align-items-center gap-1">
      //         <p className="feature-card-cutoff-p">
      //          {currencyIcn}{value}  
      //         </p>
      //       </div>
      //       </>
      //     );
      //   },
      // },
      {
        headerName: "Interests",
        field: "tags",
        width: 350,
        cellRenderer: ({ value, data }) => {
          return (
            <div>
              <div className='Card-IntrestD'>
                {value?.length > 0 && value?.map((item) => {
                  return (
                    <button key={item?.id} className="Card-IntrestP"
                    // onClick={() => {
                    //   setHastag(prevHastag => {
                    //     const newValue = item?.interest_id; // Get the new value
                    //     const updatedHastag = [...prevHastag]; // Copy the previous value
                    //     updatedHastag?.push(newValue); // Add the new value
                    //     return updatedHastag; // Return the updated value
                    //   });
                    //    setInterestDrop(true)
                    // }}
                    >
                      #{item?.user_interests?.name}
                    </button>
                  )
                })}
              </div>
            </div>
          );
        },
        // cellRenderer: AvatarRenderer,
        // filter: params => params.data.editable,
      },
      {
        headerName: "Action",
        field: "id",
        cellRenderer: ({ value, data }) => {
          const eventEndDate = new Date(`${data?.end_date} ${data?.end_time}`);
          const currentDate = new Date();
          const isEventExpired = currentDate > eventEndDate;
          return (
            data?.event_status == "Draft" || data?.event_status == "Rejected" ?
              <div className="col-md-2">
                {data?.created_by == data?.referral_id && isEventExpired ? <button className="listevent-editbtn" disabled>-</button>
                  :
                  <div className="d-flex gap-1">
                    <Link
                      to={someFilterHide ? `/EditPastEvent/${data?.id}` : data?.is_express_event ? `/createexpressevent/${data?.id}` : `/EditEvent/${data?.id}`}
                      className="create-Date-Time"
                      type="button">
                      Edit
                    </Link>
                    <button
                      className="create-Date-Time"
                      type="button"
                      onClick={() => setEventIdToDelete(data?.id)}>
                      Delete
                    </button>
                  </div>
                }
              </div> : <div className="col-md-2">-</div>

          );
        },
      },

      event_status == "Published" && profileData?.i_want_to == "is_host" &&
      {
        field: "actions",
        headerName: "Actions",
        // val?.event_status == "Published" ?                 
        cellRenderer: ({ value, data }) => {
          return (
            <div className="">
              <div className="d-flex gap-2">
                <button className="listevent-publishbtn" onClick={() => handleUnPublish(data?.id)}>
                  {unPublishBtnLoading &&
                    <div className="spinner-border text-light " role="status">
                      <span className="sr-only">Loading...</span>
                    </div>}
                  UnPublish
                </button>
              </div>
            </div>
          )
        },
      }
    ]);
  }, [currencyIcn]);

  return (<>
    <ToastContainer />
    {
      eventIdToDelete && <DeleteWarningModal
        msg={"Do You Want to Delete This Event"} eventIdToDelete={eventIdToDelete} handleDeleteEvent={handleDeleteEvent}
        setEventIdToDelete={setEventIdToDelete} />
    }

    <div className="ag-theme-alpine" style={{ height: 600, width: "100%" }}>
      <AgGridReact
        ref={gridRef}
        onGridReady={onGridReady}
        columnDefs={columnDefs}
        rowData={rowData}
        defaultColDef={defaultColDef}
        rowSelection="multiple"
      ></AgGridReact>
    </div>
    {/* <div className="listcard-div">
      <div className="row justify-content-center align-items-center">
        <div className="col-md-2">
          <Link className="navigate-detail-btn"
            to={someFilterHide ? `/pasteventdetail/${val?.id}` : `/eventdetail/${val?.id}`}
            state={{
              type: someFilterHide
                ? "Past Events"
                : "My Events"
            }}>
            <img className="listcard-img" src={`${baseUrls?.logo_image_url}/${val?.logo}`} onError={cardImageError} alt="img" />
          </Link>
        </div>
        <div className="col-md-2">
          <Link className="navigate-detail-btn"
            to={someFilterHide ? `/pasteventdetail/${val?.id}` : `/eventdetail/${val?.id}`}
            state={{
              type: someFilterHide
                ? "Past Events"
                : "My Events"
            }}>
            <p className="listcard-title">{val?.event_title}</p>
          </Link>
        </div>

        <div className="col-md-2">
          <p className="listcard-title">{moment(val?.start_date).format('DD-MM-YYYY')}</p>
        </div>

        <div className="col-md-2">
          <p className="listcard-title" title={val?.address}>{val?.address}</p>
        </div>

        <div className="col-md-2">
          <p className="listcard-title">{val?.event_status}</p>
        </div>


        {someFilterHide ? '' :
          val?.event_status == "Draft" || val?.event_status == "Rejected" ?
            <div className="col-md-2">
              {val?.created_by == val?.referral_id && isEventExpired ? <button className="listevent-editbtn" disabled>-</button>
                :
                <div className="d-flex gap-1">
                  <Link to={`/EditEvent/${val?.id}`} className="listevent-editbtn">Edit</Link>
                  <p className="listevent-editbtn">/</p>

                  <button className="listevent-editbtn" onClick={() => setEventIdToDelete(val?.id)}>Delete</button>
                </div>
              }
            </div> : <div className="col-md-2">-</div>
        }

      </div>
    </div> */}

  </>)
};

export default MyEventsListCard;