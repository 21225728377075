import { useEffect, useRef, useState } from "react";
import { MdVerified } from "react-icons/md";
import { RiShieldCheckFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { EventHost } from "../../utils/CreateEvents/createEvent";
import { Get_Edit_Event_Host } from "../../utils/EditEvent/editEvent";

import Select from "react-select";
import { Get_UserInterest } from "../../utils/basicInformation/basicinformationManager";
import ReactQuill from "react-quill";
import { IoMdInformationCircleOutline } from "react-icons/io";
import {
  get_predefine_list,
  Get_Professions,
} from "../../utils/profile/profileEditAlldata";
import { GoDotFill } from "react-icons/go";
import { UserProfile } from "../../utils/user/UserManager";
import { profileval } from "../../redux/UserProfile/Profile";
import { RxCheck } from "react-icons/rx";

const EditEventHost = ({ userData, id, setTabValue, Messages }) => {
  const [formData, setFormData] = useState({
    name: "",
    location: "",
    profession: "",
    industry: "",
    marital_status: "",
    family_details: "",
    field_of_study: "",
    known_for: "",
    tech_proficiency: "",
    industry_related_topics: "",
    social_media_usage: "",
    my_journey: "",
    type_ids: [],
  });
  const [predefineIntrest, setPredefineIntrest] = useState([]);
  const [selectEdInterest, setSelectedInterest] = useState([]);
  const [listing, setListing] = useState([]);
  const [professionData, setProfessionData] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const profileinfo = useSelector((data) => data?.Profile_data?.ProfileData);
  const ConfigData = useSelector((state) => state?.config_data?.configData);
  const baseUrls = ConfigData?.base_urls;
  const [errors, setErrors] = useState({});
  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const dispatch = useDispatch();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const GetProfession = async () => {
    try {
      const response = await Get_Professions();
      setProfessionData(response?.data?.data);
    } catch (err) {
      console.log("err", err);
    }

    try {
      await get_predefine_list().then((response) => {
        setListing(response?.data?.data);
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    GetProfession();
    // try {
    //   Get_Edit_Event_Host(id).then((response) => {
    //     const data = response?.data.event;

    //     const list = response?.data?.user_interests?.map((item) => ({
    //       value: item?.id,
    //       label: item?.name,
    //     }));
    //     setSelectedInterest(list);
    //     const interests = list?.map((val) => val?.value);
    //     setFormData((currentInput) => ({
    //       ...currentInput, // preserve old data
    //       ...data, // spread new data
    //       location: data?.address,
    //       name:`${profileinfo?.f_name} ${profileinfo?.l_name}`,
    //       field_of_study: data?.education_background,
    //     }));
    //   });
    // } catch (errr) {`
    //   console.log(errr);
    // }
  }, []);

  useEffect(() => {

    {
      profileinfo?.user_type ?
        setFormData({
          name: `${userData?.f_name ? userData?.f_name : ""} ${userData?.l_name ? userData?.l_name : ""}`,
          location: `${userData?.city_name ? userData?.city_name : ""}, ${userData?.state_name ? userData?.state_name : ""}`,
          profession: userData?.profession,
          industry: userData?.industry,
          marital_status: userData?.marital_status,
          family_details: userData?.family_details,
          field_of_study: userData?.education_background,
          known_for: userData?.known_for,
          tech_proficiency: userData?.tech_proficiency,
          industry_related_topics: userData?.industry_related_topics,
          social_media_usage: userData?.social_media_usage,
          my_journey: userData?.my_journey,
          type_ids: [],
        })
        :
        setFormData({
          name: `${profileinfo?.f_name} ${profileinfo?.l_name}`,
          location: `${profileinfo?.city_name}, ${profileinfo?.state_name}`,
          profession: profileinfo?.profession,
          industry: profileinfo?.industry,
          marital_status: profileinfo?.marital_status,
          family_details: profileinfo?.family_details,
          field_of_study: profileinfo?.education_background,
          known_for: profileinfo?.known_for,
          tech_proficiency: profileinfo?.tech_proficiency,
          industry_related_topics: profileinfo?.industry_related_topics,
          social_media_usage: profileinfo?.social_media_usage,
          my_journey: profileinfo?.my_journey,
          type_ids: [],
        })
    }
  }, [profileinfo]);


  const getProgile = async () => {
    try {
      await UserProfile().then((data) => {
        if (data?.status == 200) {
          localStorage.setItem(
            "LiveSample",
            data?.data?.user?.create_sample_events
          );
          dispatch(profileval(data?.data?.user));
        } else {
          localStorage.removeItem("LoginToken");
          window.location.reload();
        }
      });
    } catch (error) {
      console.log("profile", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validate();
    if (isValid) {
      try {
        setBtnLoading(true);
        const response = await EventHost(id, formData);
        if (response?.data?.success) {
          getProgile()
          Messages(response?.data?.message);
          setTabValue("Event-Partnerships");
        }
      } catch (err) {
        console.log("err", err);
      } finally {
        setBtnLoading(false);
      }
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.location) newErrors.location = "Location is required";
    if (!formData.profession) newErrors.profession = "Profession is required";
    if (!formData.industry) newErrors.industry = "Industry is required";
    if (!formData.marital_status)
      newErrors.marital_status = "Marital status is required";
    if (!formData.family_details)
      newErrors.family_details = "Family details are required";
    if (!formData.field_of_study)
      newErrors.field_of_study = "Field of study is required";
    if (!formData.known_for) newErrors.known_for = "Known for is required";
    if (!formData.tech_proficiency)
      newErrors.tech_proficiency = "Tech proficiency is required";
    if (!formData.industry_related_topics)
      newErrors.industry_related_topics =
        "Industry related topics are required";
    if (!formData.social_media_usage)
      newErrors.social_media_usage = "Social media usage is required";
    const jour = formData?.my_journey?.replace(/<p><br><\/p>/g, '')
    if (!jour) newErrors.my_journey = "My journey is required";
    // if (!formData.interests) newErrors.interests = "Host interests are required";
    if (formData?.type_ids?.length == 0)
      newErrors.type_ids = "Interests are required";
    setErrors(newErrors);
    // Return a boolean indicating whether there are any errors
    return Object.keys(newErrors)?.length == 0;
  };

  const handleMyjourny = (value) => {
    const text = value?.replace(/<[^>]*>?/gm, "");
    if (text?.length <= 500) {
      setFormData((prevState) => ({
        ...prevState,
        my_journey: value,
      }));
      setErrors({
        ...errors,
        my_journey: "",
      });
    } else {
      setErrors({
        ...errors,
        my_journey: "My Journey cannot exceed 500 characters.",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const GetInterest = async () => {
    try {
      await Get_UserInterest().then((response) => {
        const getInterest = response?.data;
        const options = getInterest?.map((topic) => ({
          value: topic.id,
          label: topic.name,
        }));
        setPredefineIntrest(options);

        const interestNames = profileinfo?.user_type && userData?.interest?.map(interest => interest?.user_interests?.name);

        const sData = getInterest?.filter((val) => {
          const isInterestIncluded = profileinfo?.user_type ? interestNames?.includes(val?.name) : profileinfo?.interest?.includes(val?.name);
          return isInterestIncluded;
        });

        const list = sData?.map((item) => ({
          label: item?.name,
          value: item?.id,
        }));
        setSelectedInterest(list);

        const allValues = list?.map((val) => val.value) || [];

        setFormData((currentInput) => ({
          ...currentInput,
          type_ids: JSON.stringify(allValues),
        }));
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    GetInterest();
  }, []);

  const handleSelectIntrest = (selectedOption) => {
    setSelectedInterest(selectedOption);
    const list = selectedOption?.map((item) => {
      return item.value;
    });

    setFormData({
      ...formData,
      type_ids: JSON?.stringify(list),
    });
  };

  // rect quill length count
  const stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;

    let textWithNewlines = div.innerHTML

    if (/^<p><br><\/p>$/i.test(textWithNewlines)) {
      textWithNewlines = '';  // Remove the entire content if it matches <p><br></p>
    } else {
      // Otherwise, apply replacements
      textWithNewlines = textWithNewlines
        .replace(/<br\s*\/?>/gi, '\n')  // Replace <br> with newline
    }

    div.innerHTML = textWithNewlines;
    return div.textContent || div.innerText || "";
  };

  const getDescriptionLength = () => {
    const cleanDescription = stripHtmlTags(formData.my_journey);
    return cleanDescription.length;
  };

  const maxLength = 500;
  const currentLength = getDescriptionLength();
  const remainingLength = maxLength - currentLength;
  //

  const getDurationInYearsAndMonths = (createdAt) => {
    const createdDate = new Date(createdAt);
    const now = new Date();

    let years = now.getFullYear() - createdDate.getFullYear();
    let months = now.getMonth() - createdDate.getMonth();

    // Adjust for cases where the end month is earlier than the start month
    if (months < 0) {
      years--;
      months += 12;
    }

    // Adjust for cases where the end day is earlier than the start day in the same month
    if (now.getDate() < createdDate.getDate()) {
      months--;
      if (months < 0) {
        years--;
        months += 12;
      }
    }

    return { years, months };
  }
  const duration = getDurationInYearsAndMonths(profileinfo?.created_at);

  return (
    <>
      <div className="basicInfo">
        {/* <h2 className="basicinfo-head">Event Host</h2> */}
        <div className="d-flex align-items-center gap-1 position-relative">
          <p className="createevent-head-p">
            Get to know your host! This section helps potential Sponsor’s
            learn who is organizing the event.
          </p>
          {/* <h2 className="event-heading mb-0 d-flex flex-column flex-md-row align-items-md-center gap-md-2">
            Event Host
          </h2>
          <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <IoMdInformationCircleOutline size={23} />
          </div>
          {isHovered && (
            <div
              className="info-popup"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <p className="info-popup-text">
                Get to know your host! This section helps potential Sponsor’s
                learn who is organizing the event.
              </p>
            </div>
          )} */}
        </div>

        <div className="row">
          <div className="col-12 col-lg-4 mt-4">
            <div className="profile-header-h">
              <div className="profile-picture-h position-relative">
                <img src={profileinfo?.image_type == "browse" ?
                  `${baseUrls?.customer_image_url}/${profileinfo?.image}` :
                  `${baseUrls?.avatar_image_url}/${profileinfo?.predefine_image}`
                } alt="Profile" />
                <button className="create-event-shield-btn" onClick={() => setIsOpen(!isOpen)}>
                  <RiShieldCheckFill color="white" size={14} />
                </button>
                <h5 className="py-1 create-event-shield-name">{profileinfo?.f_name}</h5>
              </div>
              <div className="profile-details-h">
                <div className="reviews-h">
                  <span className="span-text-h"><b className="b-bold-h">-</b> <br />Reviews</span>
                  <hr className="m-1" />
                  <span className="span-text-h"><b className="b-bold-h">{profileinfo?.rating_review} ★</b> <br />Rating</span>
                  <hr className="m-1" />
                  <span className="span-text-h"><b className="b-bold-h"> {duration?.years == '0' ? duration?.months : duration?.years}  </b><br />{duration?.years == '0' ? 'Months' : "Years"} hosting</span>
                </div>
              </div>
            </div>
            {isOpen &&
              <div className="confirmed-info-h mt-4">
                <p>{profileinfo?.f_name}'s confirmed information</p>
                <ul>
                  <li>{profileinfo?.verify_aadhar ? <RxCheck size={20} color="green" /> : <RxCheck size={20} color="orange" />}
                    Aadhaar Card
                  </li>
                  <li>{profileinfo?.verify_email ? <RxCheck size={20} color="green" /> : <RxCheck size={20} color="orange" />}
                    Email address
                  </li>
                  <li>{profileinfo?.verify_phone ? <RxCheck size={20} color="green" /> : <RxCheck size={20} color="orange" />}
                    Phone Number
                  </li>
                </ul>
                <div onClick={() => setShowPopup(true)}>
                  <a href='#'>Learn about identity verification</a>
                </div>
              </div>
            }
          </div>
        </div>
        {/* popup start*/}
        {showPopup &&
          <div className="logout-popup-overlay">
            <div className="logout-popup">
              <p><b>Verified Identity</b> badge means that they have provided identification information for completing our verification process.
                Safeguards have been built up in the process but is not a guarantee that someone is who they claim to be.</p>
              <button
                onClick={() => setShowPopup(false)}
                className="ok-button">OK</button>
            </div>
          </div>
        }
        {/* popup end*/}


        <div className="row mt-4">
          <div className="about-me-section">
            <h4 className="form-heading">About Me</h4>
            <div className="about-me-div gap-2">
              <p className="">Meet</p>
              <div className="d-flex flex-column">
                <input
                  className=""
                  value={formData?.name}
                  name="name"
                  placeholder="Name"
                  disabled
                  onChange={handleChange}
                />
                {errors.name && (
                  <span className="error">{errors.name}</span>
                )}
              </div>
              <p>from</p>
              <div className="d-flex flex-column">
                <input
                  className="address-input"
                  value={formData?.location}
                  name="location"
                  placeholder="Location"
                  disabled
                  onChange={handleChange}
                />
                {errors.location && (
                  <span className="error">{errors.location}</span>
                )}
              </div>
              ,<p>a passionate </p>
              <div className="d-flex flex-column">
                <select
                  className="aboutme-select-input"
                  name="profession"
                  disabled={profileinfo?.user_type}
                  onChange={handleChange}>
                  <option value="" selected>
                    Select
                  </option>
                  {professionData?.map((val, index) => (
                    <option
                      key={index}
                      value={val?.profession}
                      selected={formData?.profession == val?.profession} >
                      {val?.profession}
                    </option>
                  ))}
                </select>

                {errors.profession && (
                  <span className="error">{errors.profession}</span>
                )}
              </div>
              <p>in the</p>
              <div className="d-flex flex-column">
                <select
                  className="aboutme-select-input"
                  name="industry"
                  disabled={profileinfo?.user_type}
                  onChange={handleChange}>
                  <option value="" selected>Select</option>
                  {listing?.industry?.map((val, index) => (
                    <option
                      key={index}
                      value={val?.industry}
                      selected={formData?.industry == val?.industry}>
                      {val?.industry}
                    </option>
                  ))}
                </select>
                {errors.industry && (
                  <span className="error">{errors.industry}</span>
                )}
              </div> i am
              <div className="d-flex flex-column">
                <select
                  className="aboutme-select-input"
                  name="marital_status"
                  disabled={profileinfo?.user_type}
                  onChange={handleChange}>
                  <option value="" selected>
                    Select
                  </option>
                  <option
                    value="married"
                    selected={formData?.marital_status == "married"}>
                    Married
                  </option>
                  <option
                    value="single"
                    selected={formData?.marital_status == "single"}>
                    Single
                  </option>
                  <option
                    value="divorced/separated"
                    selected={formData?.marital_status == "divorced/separated"}>
                    Divorced / Separated
                  </option>

                  <option
                    value="widowed"
                    selected={formData?.marital_status == "widowed"}>
                    Widowed
                  </option>

                  <option
                    value="prefer not to say"
                    selected={formData?.marital_status == "prefer not to say"}>
                    Prefer not to say
                  </option>

                  <option
                    value="other"
                    selected={formData?.marital_status == "other"}>
                    Other
                  </option>
                </select>
                {errors.marital_status && (
                  <span className="error">{errors.marital_status}</span>
                )}
              </div>
              {/* <div className="d-flex flex-column">
                <input
                  className="industry-input"
                  value={formData?.industry}
                  name="industry"
                  placeholder="Industry"
                  onChange={handleChange}
                />
                {errors.industry && (
                  <span className="error">{errors.industry}</span>
                )}
              </div>
              ,
              <div className="d-flex flex-column">
                <input
                  className=""
                  value={formData?.marital_status}
                  name="marital_status"
                  placeholder="Marital Status"
                  onChange={handleChange}
                />
                {errors.marital_status && (
                  <span className="error">{errors.marital_status}</span>
                )}
              </div> */}
              <p>living with</p>
              <div className="d-flex flex-column">
                <input
                  className=""
                  value={formData?.family_details}
                  disabled={profileinfo?.user_type}
                  name="family_details"
                  placeholder="Family Details"
                  onChange={handleChange}
                />
                {errors.family_details && (
                  <span className="error">{errors.family_details}</span>
                )}
              </div>
              ,<p>Educated in</p>
              <div className="d-flex flex-column">
                <input
                  className=""
                  value={formData?.field_of_study}
                  name="field_of_study"
                  disabled={profileinfo?.user_type}
                  placeholder="Education"
                  onChange={handleChange}
                />
                {/* <select
                  className="aboutme-select-input"
                  name="field_of_study"
                  disabled={profileinfo?.user_type}
                  onChange={handleChange}>
                  <option value="" selected>
                    Select
                  </option>
                  {listing?.educational_background?.map((val) => (
                    <option
                      key={val?.id}
                      value={val?.educations}
                      selected={formData?.field_of_study == val?.educations}>
                      {val?.educations}
                    </option>
                  ))}
                </select> */}
                {errors.field_of_study && (
                  <span className="error">{errors.field_of_study}</span>
                )}
              </div>
              ,<p>I am known for </p>
              <div className="d-flex flex-column">
                <textarea
                  className=""
                  value={formData?.known_for}
                  name="known_for"
                  disabled={profileinfo?.user_type}
                  // placeholder="Host Known For"
                  cols={20}
                  onChange={handleChange}
                ></textarea>
                {errors.known_for && (
                  <span className="error">{errors.known_for}</span>
                )}
              </div>
              <p>and I use my </p>
              <div className="d-flex flex-column">
                {/* <input
                  className=""
                  value={formData?.tech_proficiency}
                  disabled={profileinfo?.user_type}
                  name="tech_proficiency"
                  placeholder="Tech Proficiency"
                  onChange={handleChange}
                /> */}
                <select
                  className="aboutme-select-input"
                  name="tech_proficiency"
                  disabled={profileinfo?.user_type}
                  onChange={handleChange}>
                  <option selected>Select</option>
                  {listing?.guest_technology_proficiency?.map((val) => (
                    <option
                      key={val?.id}
                      value={val?.proficiency_level}
                      selected={formData?.tech_proficiency == val?.proficiency_level}>
                      {val?.proficiency_level}
                    </option>
                  ))}
                </select>
                {errors.tech_proficiency && (
                  <span className="error">{errors.tech_proficiency}</span>
                )}
              </div>
              <p>to</p>
              <div className="d-flex flex-column">
                <input
                  className=""
                  value={formData?.industry_related_topics}
                  disabled={profileinfo?.user_type}
                  name="industry_related_topics"
                  placeholder="Industry-related Topics"
                  onChange={handleChange}
                />
                {errors.industry_related_topics && (
                  <span className="error">
                    {errors.industry_related_topics}
                  </span>
                )}
              </div>
              ,<p> I actively engage on Social Media Platforms with</p>
              <div className="d-flex flex-column">
                {/* <textarea
                  value={formData?.social_media_usage}
                  disabled={profileinfo?.user_type}
                  name="social_media_usage"
                  className=""
                  cols={20}
                  onChange={handleChange}
                ></textarea> */}
                <select
                  className="aboutme-select-input"
                  name="social_media_usage"
                  disabled={profileinfo?.user_type}
                  onChange={handleChange}>
                  <option selected>Select</option>
                  {listing?.guests_social_media_usage?.map((val) => (
                    <option
                      key={val?.id}
                      value={val?.usage_level}
                      selected={formData?.social_media_usage == val?.usage_level}>
                      {val?.usage_level}
                    </option>
                  ))}
                </select>
                {errors.social_media_usage && (
                  <span className="error">{errors.social_media_usage}</span>
                )}
              </div>
              <p>Social Media Usage, discussing topics related to</p>
              <div className="form-group col-md-6">
                <Select
                  defaultValue={[]}
                  isMulti
                  name="type_ids"
                  value={selectEdInterest}
                  options={predefineIntrest}
                  onChange={handleSelectIntrest}
                  classNamePrefix="Select"
                  placeholder="Select Interest"
                  isDisabled={profileinfo?.user_type}
                />
                {errors.type_ids && (
                  <span className="error">{errors.type_ids}</span>
                )}
              </div>
            </div>
          </div>

          <div className="mt-3">
            <div className="d-flex justify-content-between">
              <label className="form-heading mb-0">
                My Journey<span className="asterisk">*</span>
                {/* <IoMdInformationCircleOutline size={16} color="black" title=" Tell us about your event hosting experience! How many and what types of events have you hosted? Share key successes like how many people attended or 
                what the sponsors gained from them or if you're new, no worries! Just let us know how you plan to make your event awesome and valuable for sponsors. Sponsors are eager to hear your story! " /> */}
              </label>
              <p className="Character-0500">
                Character:
                <span className="text-style-1">
                  {/* remainingLength */}
                  {currentLength}/{maxLength}
                </span>
              </p>
            </div>
            <p className="reactquill-tooltip-p mb-1">(Note: Tell us about your event hosting experience! How many and what types of events have you hosted? Share key successes like how many people attended or
              what the sponsors gained from them or if you're new, no worries! Just let us know how you plan to make your event awesome and valuable for sponsors. Sponsors are eager to hear your story!)</p>
            <div className="d-flex flex-column">
              <div style={{ height: "220px" }}>
                <ReactQuill
                  value={formData?.my_journey}
                  onChange={handleMyjourny}
                  theme="snow"
                  style={{ height: "150px" }}
                  placeholder="Enter My journey"
                />
              </div>
              {errors.my_journey && (
                <span className="error">{errors.my_journey}</span>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-md-end align-items-center mt-3 mt-sm-0">
            <div className="d-flex flex-column flex-md-row gap-2">
              {/* <button
                        className="d-flex align-items-center gap-1 btn saveandcontinue-btn"
                        type="button"
                        onClick={handleSaveData}
                        >
                        {saveBtnLoading ? (
                          <div
                            className="spinner-border text-light "
                            role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          ""
                        )}
                        Save
                      </button> */}

              <button
                className="d-flex align-items-center gap-1 saveandcontinue-btn"
                onClick={handleSubmit}>
                {btnLoading ? (
                  <div className="spinner-border text-light " role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  ""
                )}
                Save and Continue
                {/* <img className="arrow-img" src={arrow} alt="arrow" /> */}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditEventHost;
